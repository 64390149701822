import React from 'react';
import Spinner from "components/Spinner"
import loadable from '@loadable/component';

const LoadableHome = loadable(() => import('components/HomePage'), {
  fallback: (
    <Spinner />
  ),
});

const IndexPage = () => {
  return <LoadableHome />;
};

export default IndexPage;
